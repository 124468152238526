import { Injectable, inject } from '@angular/core';

import { HeaderParameter } from '@sulser-print/constants/header-parameters';
import { Response } from '@sulser-print/models/response';
import { Tenant } from '@sulser-print/models/tenant/tenant';
import { tap } from 'rxjs';

import { RequestService } from './request.service';
import { TenantsStore } from './tenants.store';

/**
 * Tenants service.
 */
@Injectable({ providedIn: 'root' })
export class TenantsService extends RequestService {
	/**
	 * Api uri.
	 */
	readonly apiUri: string = 'res_tenants';

	tenantsStore = inject(TenantsStore);

	getTenantAuthLogo() {
		return this.httpClient.get<Response<string>>(`file_shares/tenant_auth_logo`, { headers: { [HeaderParameter.PUBLIC]: 'true' } });
	}

	getTenantBackgroundLogo(tenantId: Tenant['id']) {
		return this.httpClient.get<Response<string>>(`file_shares/tenant_background_logo/${tenantId}`, {
			headers: { [HeaderParameter.PUBLIC]: 'true' },
		});
	}

	getTenantLogo() {
		return this.httpClient.get<Response<string>>(`file_shares/tenant_logo`, { headers: { [HeaderParameter.PUBLIC]: 'true' } });
	}

	initTenant() {
		return this.httpClient
			.get<Response<Tenant>>(`${this.apiUri}/current-tenant`, { headers: { [HeaderParameter.PUBLIC]: 'true' } })
			.pipe(
				tap((response) => {
					const { data } = response;
					if (data) {
						this.tenantsStore.setActiveTenant(data);
					}
				}),
			);
	}

	// /**
	//  * Read.
	//  *
	//  * @param params Read params.
	//  */
	// read(params?: any) {
	// 	return this.requestService.request<TableData<Tenant>>({
	// 		url: `${this.apiUri}/${ApiActions.READ}`,
	// 		options: { params },
	// 	});
	// }
	//
	// /**
	//  * Delete.
	//  *
	//  * @param id Tenant id.
	//  */
	// delete(id: number): Observable<Response<any>> {
	// 	return this.requestService.request({
	// 		url: `${this.apiUri}/${ApiActions.DELETE}/${id}`,
	// 		method: RequestMethod.DELETE,
	// 	});
	// }
}
