import { Injectable, signal } from '@angular/core';

import { Tenant } from '@sulser-print/models/tenant/tenant';

@Injectable()
export class TenantsStore {
	private readonly _active = signal<Tenant | undefined>(undefined);

	readonly active = this._active.asReadonly();

	setActiveTenant(tenant: Tenant) {
		this._active.set(tenant);
	}
}
